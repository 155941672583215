import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div className="contact">
            <h2>Contact Us</h2>
            <p>For inquiries, please contact us at:</p>
            <ul>
                <li>Email: info@mosque.com</li>
                <li>Phone: (123) 456-7890</li>
                <li>Address: 123 Mosque Lane, City, Country</li>
            </ul>
        </div>
    );
};

export default Contact;
