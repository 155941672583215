import React from 'react';
import './News.css';

const News = () => {
    return (
        <div className="news">
            <h2 style={{textAlign: 'center'}}>News and Updates</h2>
            <ul>
                <li>Upcoming news and events will be listed here soon!</li>
            </ul>
        </div>
    );
};

export default News;
