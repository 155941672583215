import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Banner from './Banner';
import Menu from './Menu';
import News from './News';
import PrayerTimes from './PrayerTimes';
import Contact from './Contact';

function App() {
    return (
        <Router>
            <div className="App">
                <Banner />
                <Menu />
                <div className="content">
                    <Routes>
                        <Route path="/" element={<News />} />
                        <Route path="/news" element={<News />} />
                        <Route path="/prayer-times" element={<PrayerTimes />} />
                        <Route path="/contact" element={<Contact />} />
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
