import React from 'react';
import './PrayerTimes.css';
// import prayerTimesImage from './prayer-times.jpg'; // Import the image

const PrayerTimes = () => {
    return (
        <div className="prayer-times">
            <h2 style={{textAlign: 'center'}}>Prayer Times</h2>
            <img src={'PrayerTimeTable.png'} alt="Prayer Times" className="prayer-times-image" />
        </div>
    );
};

export default PrayerTimes;
