import React from 'react';
import { Link } from 'react-router-dom';
import './Menu.css';

const Menu = () => {
    return (
        <nav className="menu">
            <ul>
                <ul style={{display: 'flex', justifyContent: 'center'}}>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/news">News</Link></li>
                    <li><Link to="/prayer-times">Prayer Times</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>
                {/* <li><Link to="/">Home</Link></li> */}
                {/* <li><Link to="/news">News</Link></li> */}
                {/* <li><Link to="/prayer-times">Prayer Times</Link></li> */}
                {/* <li><Link to="/contact">Contact</Link></li> */}
            </ul>
        </nav>
    );
};

export default Menu;
