import React from 'react';
import './Banner.css';
// import bannerImage from './Royals Building Image.JPG'; // Import the image
// import publicFolder from '../public'; // Import the image

const Banner = () => {
    return (
        <div className="banner" style={{ textAlign: 'center', padding: '20px' }}>
            <img 
                src={`1.png`} 
                alt="Mosque Banner" 
                style={{ 
                    width: '400px', // Set a fixed width
                    height: 'auto', // Maintain aspect ratio
                    display: 'block', 
                    margin: '0 auto' 
                }} 
            />
        </div>
    );
};

export default Banner;
